<template>
  <f7-page class="profile-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="mode === 'follows' ? $t.getTranslation('LBL_FOLLOWS') : $t.getTranslation('LBL_FOLLOWERS')" settings="false" />
    </template>

    <section class="userlist-container">
      <template v-if="userFollowList && userFollowList.length > 0">
        <div v-for="userFollow in userFollowList" :key="'u_' + userFollow.UserFollowId" class="user-item" @click="onSelectUser(userFollow)">
          <template v-if="userFollow.Type == 'USER'">
            <img :src="$h.getImage(userFollow.Image, 'USER')" :alt="$t.getTranslation('LBL_USER_IMAGE')" loading="lazy" />
            <div @click="onSelectUser(userFollow)">
              <h4>{{ userFollow?.Username || "NOT FOUND" }}</h4>
              <h5>{{ $h.getFullName(userFollow) || "User not found." }}</h5>
            </div>
          </template>

          <template v-if="userFollow.Type == 'MERCHANT'">
            <img :src="$h.getImage(userFollow.ProfileBannerImage, 'MERCHANT')" :alt="$t.getTranslation('LBL_MERCHANT_IMAGE')" loading="lazy" />
            <div @click="onSelectUser(userFollow)">
              <h4>{{ userFollow?.MerchantName || "NOT FOUND" }}</h4>
              <h5>{{ $h.formatTrimString(userFollow?.MerchantDescription) || "Merchant not found." }}</h5>
            </div>
          </template>
        </div>
      </template>

      <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_NO_DATA_FOUND')" />
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "UserListPage",
  components: {
    ProfileNavigationComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    let page = 1;
    let size = 25;
    let lastPage = 1;

    const store = useStore();

    const mode = ref("");
    const searchValue = ref("");
    const showPreloader = ref(false);

    const userFollowList = ref([]);

    const getUserFollowList = async () => {
      let returnData = await get("/follow/list", {
        Type: "USER,MERCHANT",
        mode: mode.value,
        page: page,
        size: size,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
      });

      if (returnData && returnData.data && Array.isArray(returnData.data)) {
        for (let counter = 0; counter < returnData.data.length; counter++) {
          let item = returnData.data[counter];
          userFollowList.value.push(item);
        }

        lastPage = returnData?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getUserFollowList() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      userFollowList.value = [];
      page = 1;

      getUserFollowList();
    };

    const onSelectUser = (userFollow) => {
      if (userFollow.Type == "USER" && userFollow.UserKey) props.f7router.navigate({ name: "influencerViewPage", params: { code: userFollow.UserKey } });
      if (userFollow.Type == "MERCHANT" && userFollow.MerchantKey) props.f7router.navigate({ name: "merchantViewPage", params: { code: userFollow.MerchantKey } });
    };

    onMounted(() => {
      if (props?.f7route?.query?.search) searchValue.value = props?.f7route?.query?.search;
      mode.value = props?.f7route?.params?.mode;

      getUserFollowList();
    });

    return {
      mode,
      showPreloader,
      userFollowList,
      loadMore,
      search,
      onSelectUser,
    };
  },
});
</script>

<style scoped></style>
